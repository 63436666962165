import { Outlet } from 'react-router-dom';
import Header from '../MainLayout/Header/Header';
import './index.scss';
import Footer from '../MainLayout/Footer/Footer';

export default function NoAccessLayout() {
  return (
    <div className="no-access-layout">
      <Header></Header>
      <div className="no-access-layout-content">
        <div className="no-access-layout-container">
          <Outlet />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
