import { LanguageHelper } from '@onix/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../core/constants/routes.constants';
import { UserAccount } from '../../models/user-account.model';
import { getInformation } from '../../services/account.service';
import { getUser } from '../../services/authentication.service';
import { appSelector } from '../../states/app/appSlice';
import { appDispatch } from '../../states/store';
import { UserActions } from '../../states/user/userSlice';
import Header from './Header/Header';
import LeftMenu from './LeftMenu/LeftMenu';
import './index.scss';
import Footer from './Footer/Footer';

export default function MainLayout() {
  const { i18n } = useTranslation();
  const dispatch = appDispatch;
  const [isLoading, setLoading] = useState(true);
  const { triggerReloadUserInfoId } = useSelector(appSelector);

  const navigation = useNavigate();

  useEffect(() => {
    loadContact();
  }, []);

  useEffect(() => {
    if (triggerReloadUserInfoId !== undefined) {
      loadContact();
    }
  }, [triggerReloadUserInfoId]);

  const loadContact = async () => {
    const userAccount = (await getInformation()) as UserAccount;
    const user = await getUser();

    userAccount.email = user ? user.profile.sub : '';
    dispatch(UserActions.updateUser(userAccount));
    if (userAccount.languageId != null) {
      const languageCode = LanguageHelper.getLanguageCode(userAccount.languageId);
      i18n.changeLanguage(languageCode);
    }

    if (!userAccount.isGlobalAdmin && !userAccount.onixMakerCanEdit) {
      navigation(`/${RouteConstants.NoAccess}`);
    }
    setLoading(false);
  };

  //#endregion File upload

  return (
    <div className="home-layout">
      <Header></Header>
      {!isLoading && (
        <>
          <div className="home-layout-content">
            <LeftMenu></LeftMenu>
            <div className="home-layout-container">
              <Outlet />
            </div>
          </div>

          <Footer></Footer>
        </>
      )}
    </div>
  );
}
