import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ApplicationSetting from '../../../core/constants/application.constants';
import { userSelector } from '../../../states/user/userSlice';
import './index.scss';

type EnvironmentKeys = 'dev' | 'beta' | 'test' | 'sandbox' | 'prod';

const getEnvironmentConfigs = (env: EnvironmentKeys, localAdmin: boolean) => {
    const configs = {
        dev: {
            enabled: false,
            background: 'blue',
            environmentName: 'Dev'
        },
        beta: {
            enabled: false,
            background: 'yellow',
            environmentName: 'Beta'
        },
        test: {
            enabled: localAdmin,
            background: 'blue',
            environmentName: 'Test'
        },
        sandbox: {
            enabled: true,
            background: 'yellow',
            environmentName: 'Sandbox'
        },
        prod: {
            enabled: localAdmin,
            background: 'blue',
            environmentName: 'Production'
        }
    }

    return configs[env];
}

export default function Footer() {
    const [translate] = useTranslation();
    const user = useSelector(userSelector);
    const env = ApplicationSetting.name.toLowerCase() as EnvironmentKeys;
    const { enabled, background, environmentName } = getEnvironmentConfigs(env, user.localAdmin);

    return (
        enabled ? (
            <div className={`environment-bottom-bar ${background}`}>
                <span className="customer-status capitalize">{user.localAdmin ? translate('CommonResource.customer') : ''}&nbsp;</span>
                <span className="environment-name capitalize">{environmentName}&nbsp;</span>
                <span>{translate('CaptionResource.Environment')}</span>
            </div>
        ) : <div></div>
    )
}