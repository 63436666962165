import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAccount } from '../../models/user-account.model';
import { RootState } from '../store';

const initialState: UserAccount = {
  userAccountId: 0,
  contactId: 0,
  email: '',
  userFirstName: '',
  userLastName: '',
  fullName: '',
  userCellPhone: '',
  mainCompanyId: 0,
  companyName: '',
  picture: '',
  signature: '',
  languageId: 0,
  domain: '',
  loginUrl: '',
  ssoEnforcement: false,
  hasMultipleOrganization: false,
  isGlobalAdmin: false,
  companyTypes: [],
  onixMakerCanEdit: false,
  companyId: 0,
  disabled: false,
  totalCompany: 0,
  localAdmin: false,
};

const UserSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserAccount>) => {
      const payload = action.payload;
      const picture = payload?.picture ? 'data:image/png;base64,' + payload.picture : '';
      const signature = payload?.signature ? 'data:image/png;base64,' + payload.signature : '';
      state.fullName = payload.fullName;
      state.userFirstName = payload.userFirstName;
      state.userLastName = payload.userLastName;
      state.email = payload.email;
      state.picture = picture;
      state.signature = signature;
      state.userCellPhone = payload.userCellPhone;
      state.languageId = payload.languageId;
      state.ssoEnforcement = payload.ssoEnforcement;
      state.companyName = payload.companyName;
      state.hasMultipleOrganization = payload.hasMultipleOrganization;
      state.isGlobalAdmin = payload.isGlobalAdmin;
      state.localAdmin = payload.localAdmin;
      state.companyTypes = payload.companyTypes;
      if (payload.email) {
        state.domain = payload.email.substring(payload.email.lastIndexOf('@'));
      }
      state.onixMakerCanEdit = payload.onixMakerCanEdit;
      state.companyId = payload.companyId;
      state.contactId = payload.contactId;
      state.userAccountId = payload.userAccountId;
      state.disabled = payload.disabled;
      state.totalCompany = payload.totalCompany;
    },
    updateCompanyId: (state, action: PayloadAction<number>) => {
      state.companyId = action.payload;
    },
    updateInformation: (state, action: PayloadAction<UserAccount>) => {
      const payload = action.payload;
      state.fullName = payload.fullName;
      state.userFirstName = payload.userFirstName;
      state.userLastName = payload.userLastName;
      state.languageId = payload.languageId;
      state.userCellPhone = payload.userCellPhone;
    },
    updateUserPicture: (state, action: PayloadAction<string>) => {
      state.picture = action.payload ? 'data:image/png;base64,' + action.payload : '';
    },
    updateSignaturePicture: (state, action: PayloadAction<string>) => {
      state.signature = action.payload ? 'data:image/png;base64,' + action.payload : '';
    },
    updateLanguage: (state, action: PayloadAction<number>) => {
      state.languageId = action.payload;
    },
  },
});

export const UserActions = UserSlice.actions;

export const UserReducer = UserSlice.reducer;

// SELECTOR
const selectSelf = (state: RootState) => state.user;
export const userSelector = createSelector(selectSelf, (state) => state);
export const userImageUrlSelector = createSelector(selectSelf, (state) => state.picture);
export const signaturePictureSelector = createSelector(selectSelf, (state) => state.signature);
export const domainEmailSelector = createSelector(selectSelf, (state) => state.domain);
